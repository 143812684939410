import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnchor } from "@fortawesome/free-solid-svg-icons"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

import { faBars } from "@fortawesome/free-solid-svg-icons"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"

import Image from "../components/image"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql } from "gatsby"

const links = ["Home", "Nodi Marinari", "Quadri Nodi Marinari"]

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { menuOpen: false, dropdownOpen: false }
  }

  handleMenuClick() {
    this.setState(state => ({ menuOpen: !state.menuOpen }))
  }

  handleDropDownClick() {
    this.setState(state => ({ dropdownOpen: !state.dropdownOpen }))
  }

  renderMenuCol() {
    return (
      <div className="flex flex-col text-center bg-white shadow">
        <Link
          to=""
          className="mr-6 font-bold text-gray-500 hover:text-blue-500 text-base md:text-lg lg:text-xl"
        >
          Home
        </Link>
        <button
          onClick={this.handleDropDownClick.bind(this)}
          className="mr-6 font-bold text-gray-500 hover:text-blue-500 text-base md:text-lg lg:text-xl"
        >
          Nodi Marinari
          <FontAwesomeIcon
            className="fill-current text-blue-600 hover:text-blue-400 "
            icon={faCaretDown}
            size=""
          />
        </button>

        {this.state.dropdownOpen && this.renderDropDown()}
        <Link
          to=""
          className="mr-6 font-bold text-gray-500 hover:text-blue-500 text-base md:text-lg lg:text-xl"
        >
          Quadri Nodi Marinari
        </Link>
        <a
          href="https://www.facebook.com/nodimarinari/"
          className="mr-6 font-bold text-gray-500 hover:text-blue-500 text-base md:text-lg lg:text-xl"
        >
          Facebook
        </a>
      </div>
    )
  }

  renderDropDown(){
    return (
      <div className="rounded shadow-md bg-white absolute mt-12 sm:mt-0 ">
        <ul className="list-reset">
          <li>
            <Link
              to="nodi-marinari-di-arresto"
              className="px-4 py-2 block text-black hover:text-blue-500"
            >
              Nodi marinari di arresto
            </Link>
          </li>
          <li>
            <Link
              to="nodi-marinari-di-avvolgimento"
              className="px-4 py-2 block text-black hover:text-blue-500"
            >
              Nodi marinari di avvolgimento
            </Link>
          </li>
          <li>
            <Link
              to="nodi-marinari-di-giunzione"
              className="px-4 py-2 block text-black hover:text-blue-500"
            >
              Nodi marinari di Giunzione
            </Link>
          </li>
          <li>
            <Link
              to="nodi-marinari-di-accorciamento-e-regolazione"
              className="px-4 py-2 block text-black hover:text-blue-500"
            >
              Nodi marinari di Accorciamento
            </Link>
          </li>
          <li>
            <Link
              to="nodi-marinari-a-occhio"
              className="px-4 py-2 block text-black hover:text-blue-500"
            >
              Nodi marinari a Occhio
            </Link>
          </li>
        </ul>
      </div>
    )
  }

  renderMenuRow() {
    return (
      <div className="flex hidden sm:block ml-12 justify-center items-center">
        <Link
          to=""
          className="mr-6 font-bold text-gray-500 hover:text-blue-500 text-base md:text-lg lg:text-xl"
        >
          Home
        </Link>
        <button
          onClick={this.handleDropDownClick.bind(this)}
          className="mr-6 font-bold text-gray-500 hover:text-blue-500 text-base md:text-lg lg:text-xl"
        >
          Nodi Marinari
          <FontAwesomeIcon
            className="fill-current text-blue-600 hover:text-blue-400 "
            icon={faCaretDown}
            size=""
          />
        </button>

        {this.state.dropdownOpen && this.renderDropDown()}

        <Link
          to=""
          className="mr-6 font-bold text-gray-500 hover:text-blue-500 text-base md:text-lg lg:text-xl"
        >
          Quadri Nodi Marinari
        </Link>
        <a
          href="https://www.facebook.com/nodimarinari"
          className="mr-6 font-bold text-gray-500 hover:text-blue-500 text-base md:text-lg lg:text-xl"
        >
          Facebook
        </a>
      </div>
    )
  }

  render() {
    const image = this.props.data.desktop.childImageSharp.fluid
    return (
      <BackgroundImage style={{ minHeight: 500 }} fluid={image}>
        <div className="p-2 flex flex-row bg-white shadow-md items-center">
          <FontAwesomeIcon
            className="text-blue-600 stroke-current text-black"
            size="2x"
            icon={faAnchor}
          />
          <div className="ml-2 w-1/3 sm:w-1/4 md:w-1/4 lg:w-1/6 xl:w-1/12 flex items-center">
            <Link className="flex-1" to="">
              <Image
                filename="nodi-marinari-logo.png"
                className="flex-1"
                alt="Nodi Marinari - Logo"
              />
            </Link>
          </div>
          <button
            className="ml-auto sm:hidden"
            onClick={this.handleMenuClick.bind(this)}
          >
            <FontAwesomeIcon
              className="fill-current text-blue-600 hover:text-blue-400 "
              icon={faBars}
              size="2x"
            />
          </button>
          {this.renderMenuRow()}
        </div>
        {this.state.menuOpen && this.renderMenuCol()}
        <div className="text-center pt-32 font-sans">
          <h1 className="text-white text-4xl sm:text-6xl font-bold">
            Nodi marinari
          </h1>
          <h2 className="text-gray-100 italic text-xl font-bold">
            fatti a mano con{" "}
            <FontAwesomeIcon
              className="ml-2 fill-current text-blue-500 hover:text-blue-400"
              icon={faHeart}
            />
          </h2>
        </div>
      </BackgroundImage>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "nodi-marinari-banner.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props}></Header>}
  />
)
