import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faFacebook } from "@fortawesome/free-brands-svg-icons"



const Footer = () => (
  <footer className="mt-12 flex flex-col text-center bg-gray-900 justify-center p-5 rounded-t-lg">
    <Link className="flex-1" to="">
      <Image
        filename="nodi-marinari-logo.png"
        className="w-1/2 sm:w-1/12 mx-auto"
        alt="Nodi Marinari - Logo"
      />
    </Link>
    <p className="italic text-white mt-5">
      I nodi marinari rappresentano il modo più semplice per impiegare i cavi in
      imbarcazione. Con i nodi si possono legare due cavi, sostenere oggetti o
      tenerli uniti tra di loro. Scopri tutti i nodi marinari nel nostro sito.
      <br />
      Puoi anche acquistare i nostri quadri di nodi marinari ornamentali
      cliccando{" "}
      <a className="font-bold text-blue-500 hover:text-blue-300">qui</a>
    </p>
    <a href="https://www.facebook.com/nodimarinari/" >
      <FontAwesomeIcon
        className="mt-2 mx-auto fill-current text-blue-500 hover:text-blue-400 "
        icon={faFacebook}
        size="2x"
      />
    </a>

    <p className="text-white mt-5">
      <Link to="" className="hover:text-blue-500">Home</Link> {" | "}
      <Link to="nodi-marinari-di-arresto" className="hover:text-blue-500">Nodi Marinari di Arresto</Link>
      {" | "}
      <Link to="nodi-marinari-di-giunzione" className="hover:text-blue-500">Nodi Marinari di Giunzione</Link>
      {" | "}
      <Link to="nodi-marinari-di-avvolgimento" className="hover:text-blue-500">Nodi Marinari di Avvolgimento</Link>
      {" | "}
      <Link to="nodi-marinari-di-accorciamento-e-regolazione" className="hover:text-blue-500">
        Nodi Marinari di Accorciamento
      </Link>
      {" | "}
      <Link to="nodi-marinari-a-occhio" className="hover:text-blue-500">Nodi Marinari a Occhio</Link>
      {" | "}
      <Link className="hover:text-blue-500">Quadri Nodi Marinari</Link>
    </p>
    <p className="text-white mt-12 text-sm">
      © 2019, Creato con Gatsby da{" "}
      <a className="underline hover:text-blue-500" href="https://fabiorunci.it">
        Fabio Runci
      </a>
    </p>
  </footer>
)
export default Footer
